import React from "react";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import imageMetaData from "../../.sourceflow/image_metadata.json";
import md5 from "md5";

export default function ImageEditable({ src, path, size, alt, ...props }) {
  if (src) {
    return (
      <SourceFlowImage
        src={src}
        path={path ? path : md5(src)}
        imagesMetaData={imageMetaData}
        size={size}
        alt={alt}
        {...props}
      />
    );
  }
  return (
    <SourceFlowImage
      path={path ? path : md5(src)}
      imagesMetaData={imageMetaData}
      size={size}
      alt={alt}
      {...props}
    />
  );
}

ImageEditable.defaultProps = {
  src: null,
  path: null,
  size: "800x",
  alt: "",
};
